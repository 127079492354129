import React from 'react'
import {useTranslation} from '@wix/yoshi-flow-editor'
import {Button, Text} from 'wix-ui-tpa/cssVars'
import {TextTypography} from 'wix-ui-tpa'

import {ReserveReservationApplicationErrorCode} from '../../actions/reserveReservation'
import {Modal} from '../../../../components-shared/Modal'

import {classes, st} from './styles.st.css'
import {useHooks} from './useHooks'

interface Texts {
  title: string
  text: string
  ctaButton: string
}

const DEFAULT_TEXTS: Texts = {
  title: 'uou-reservations.reservation-details.error-modal.title',
  text: 'uou-reservations.reservation-details.error-modal.text',
  ctaButton: 'uou-reservations.reservation-details.error-modal.cta',
}

const APPLICATION_ERROR_CODE_TO_TEXTS: Record<ReserveReservationApplicationErrorCode, Texts> = {
  [ReserveReservationApplicationErrorCode.HELD_EXPIRED]: {
    title: 'uou-reservations.reservation-details.held-modal.title',
    text: 'uou-reservations.reservation-details.held-modal.text',
    ctaButton: 'uou-reservations.reservation-details.held-modal.cta',
  },
}

export const ErrorModal: React.FC = () => {
  const {t} = useTranslation()
  const {isModalOpen, onClose, onCtaButtonClick, apiRequestErrorDetails} = useHooks()

  const {title, text, ctaButton} = apiRequestErrorDetails?.applicationCode
    ? APPLICATION_ERROR_CODE_TO_TEXTS[apiRequestErrorDetails.applicationCode] ?? DEFAULT_TEXTS
    : DEFAULT_TEXTS

  return (
    <Modal isOpen={isModalOpen} onClose={onClose}>
      <Text tagName="H2" typography={TextTypography.smallTitle} className={classes.title}>
        {t(title)}
      </Text>
      <Text className={classes.text}>{t(text)}</Text>
      <Button onClick={onCtaButtonClick} className={classes.ctaButton}>
        {t(ctaButton)}
      </Button>
    </Modal>
  )
}
